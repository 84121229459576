import React, { useEffect, useState } from "react";
import { Heading, Stack, Text } from "@chakra-ui/react"

import LoadingSpinner from "../../components/LoadingSpinner";
import {CourseData, PortfolioPartnerData, SolutionTypeData} from '../../types'
import BasePage from "../BasePage"
import CourseCardCaoursel from "./CourseCardCarousel";
import SolutionTypePartnersCardCarousel from "./SolutionTypePartnersCardCarousel";
import MoreLearningSection from "./MoreLearningSection";


type LearningPageState = {
  coursesData: CourseData[];
  partnersData: PortfolioPartnerData[];
  solutionTypesData: SolutionTypeData[];
  loading: boolean;
}

function LearningPage() {
  const [state, setState] = useState<LearningPageState>({
    coursesData: [],
    partnersData: [],
    solutionTypesData: [],
    loading: true,
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const learningData = await (
          fetch("/data/learningData.json").then(response => response.json())
        );
        setState(prevState => ({ ...prevState, coursesData: learningData.coursesData}));

        const solutionTypesData = await (
          fetch("/data/solutionTypeData.json").then(response => response.json())
        );
        setState(prevState => ({ ...prevState, solutionTypesData }));

        const partnersData = await (
          fetch("/data/partnerData.json").then(response => response.json())
        );
        setState(prevState => ({ ...prevState, partnersData: partnersData.partnersInPortfolio, loading: false }));
      } catch (error) {
        console.error(error);
        setState(prevState => ({ ...prevState, loading: false }));
      }
    };

    fetchData();
  }, [setState]);

  const renderCourseCardsSection = () => {
    return (
      <Stack spacing={4}>
        <Heading as="h3" size="md">Climate change courses</Heading>
        {
          state.loading ? 
          <LoadingSpinner /> 
          : <CourseCardCaoursel coursesData={state.coursesData} />
        }
      </Stack>
    )
  }

  const renderSolutionTypeLearningCardsSection = () => {
    if (state.loading) { return null; }
    return (
      <Stack spacing={4}>
        <Heading as="h3" size="md">Carbon Removal</Heading>
        {
          state.loading ? 
          <LoadingSpinner /> 
          : <SolutionTypePartnersCardCarousel 
            solutionTypesData={state.solutionTypesData}
            partnersData={state.partnersData}
          />
        }
      </Stack>
    )
  }

  return BasePage(
    <Stack spacing={16} p={4}>
      <Stack spacing={4}>
        <Heading as="h1" size="lg">Learning hub</Heading>
        <Text fontSize="md">
        We're equipping people with the knowledge & tools they need to tackle climate change. Reimagining the future through hope-filled climate education and action. We break down the science in bite-sized lessons to enlighten, empower & catalyze action.
        </Text>
      </Stack>

      {state.loading && <LoadingSpinner />}
      {renderCourseCardsSection()}
      {renderSolutionTypeLearningCardsSection()}
      <MoreLearningSection />
    </Stack>
  );
}

export default LearningPage;
