import React from "react";

import {Box, Center} from "@chakra-ui/react"

import BasicCarousel from "../../components/BasicCarousel";
import {PortfolioPartnerData, SolutionTypeData} from '../../types'
import SolutionTypePartnersCard from "./SolutionTypePartnersCard";

interface SolutionTypePartnersCardCarouselProps {
  solutionTypesData: SolutionTypeData[],
  partnersData: PortfolioPartnerData[];
};

const SolutionTypePartnersCardCarousel = (
  {solutionTypesData, partnersData}: SolutionTypePartnersCardCarouselProps
) => {
  return (
    <BasicCarousel>
      {
        solutionTypesData.map((solutionTypeData) => (
          <Center h={650} key={solutionTypeData.name}>
            <Box h={600} maxW="500px" marginX={3}>
              <SolutionTypePartnersCard 
                solutionTypeData={solutionTypeData} 
                partnersData={partnersData.filter(
                  (partner: PortfolioPartnerData) => (
                    partner.solutionType === solutionTypeData.id && partner.isActive
                  )
                )}
              />
            </Box>
          </Center>
        ))
      }
    </BasicCarousel>
  )
};


export default SolutionTypePartnersCardCarousel;
