import { Link as ChakraLink } from "@chakra-ui/react";
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Link as RouterLink, useNavigate } from 'react-router-dom';

interface BackButtonProps {
  text: string;
  url?: string;
}

const BackButton = ({ text, url }: BackButtonProps) => {
  const navigate = useNavigate();

  const backIcon = <ChevronLeftIcon boxSize={7}/>;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (url) {
      navigate(url);
    } else {
      navigate(-1); // Navigate to the last page visited
    }
  };

  return (
    <ChakraLink as={RouterLink} onClick={handleClick}>
      {backIcon}{text}
    </ChakraLink>
  );
};

export default BackButton;
