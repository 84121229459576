import React from "react";
import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import {CarbonEquivalenceMilestone} from '../../types'

const Markdown = React.lazy(() => import("react-markdown"));

interface DashboardBlurbSectionProps {
  milestones: CarbonEquivalenceMilestone[]
  kgCarbonRemoved: number;
};

const DashboardBlurbSection = ({milestones, kgCarbonRemoved}: DashboardBlurbSectionProps) => {
  const sortedMilestonsDescending = milestones.sort(
    (a, b) => {return b.kgCarbonRemoval - a.kgCarbonRemoval }
  );

  const milestone = sortedMilestonsDescending.find(milestone => milestone.kgCarbonRemoval <= kgCarbonRemoved);
  const milestoneText = milestone ? milestone.text : (
    "Great to have you with us. Your Zopeful contributions will help remove carbon " +
    "from the atmosphere and safely store it. It's locked away where it can't " +
    "contribute to climate change.\n\nKeep going!"
  );

  return (
    <Box
      flexDirection="column"
      w="100%"
      h="100%"
      display='flex'
      alignItems='center'
      justifyContent='center'
      textAlign="left"
      color='black'
      background={
        `url('${
          milestone ? milestone.imageLink : "/assets/equivalence-images/white_bg_plant.jpg"
        }') center/cover no-repeat`}
      borderRadius="xl"
    >
      <Box
        w="100%"
        h="100%"
        brightness="10%"
        filter="auto"
        borderRadius="xl"
        bgGradient='linear(to-b, rgba(255,255,255,0.02), rgba(255,255,255,0.2))'
      >
        <Stack p={4}>
          <Text fontSize="xl" mb={1} fontWeight="700" brightness="100%">
            Thank you!
          </Text>
          <Markdown>{milestoneText}</Markdown>
        </Stack>
        <Button m={4} as={Link} to="/contributions" colorScheme="green" >
          Manage your contributions
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardBlurbSection;
