import React from "react";
import { Card, CardHeader, CardBody, Heading, Icon, SimpleGrid, Text } from '@chakra-ui/react'
import { FaCalendarCheck, FaCloudDownloadAlt, FaHandshake } from "react-icons/fa";
import { FaCirclePlus, FaMagnifyingGlassChart, FaSquareCheck } from "react-icons/fa6";

const methodologyBadgeData = [
  {
    "title": "Permanence",
    "description": "Of paramount importance, how long does the solution lock planet-heating CO₂ away for? The longer the better.",
    "icon": FaCloudDownloadAlt,
  },
  {
    "title": "Additionality",
    "description": "How high a net negativity does a removal activity have and without it would the CO₂ have contributed to heating?",
    "icon": FaCirclePlus,
  },
  {
    "title": "Verifiability",
    "description": "Can we track and measure that the CO₂ has actually been removed in a robust and scientifically defensible way?",
    "icon": FaSquareCheck,
  },
  {
    "title": "Accredited",
    "description": "Are the solutions seeking independent third-party audits of their activities and processes to ensure high quality removal?",
    "icon": FaMagnifyingGlassChart,
  },
  {
    "title": "Vintage",
    "description": "How soon will it happen and are the credits produced in a credible year for when the CO₂ will be removed?",
    "icon": FaCalendarCheck,
  },
  {
    "title": "Co-benefits",
    "description": "Does it actively seek climate justice and non-CO₂ co-benefits that contribute to planetary and human wellbeing?",
    "icon": FaHandshake,
  },
]

function MethodologyBadges() {
  return (
    <SimpleGrid columns={3} spacing={4}>
      {methodologyBadgeData.map((badge) => {
        return (
          <Card key={badge.title} alignItems="center" borderRadius="2xl">
            <CardHeader pb="8px">
              <Icon 
                as={badge.icon} 
                fontSize="32px" 
                color="green.500" 
                position="absolute" 
                left={0} 
                ml="20px"
              /> 
              <Heading size='md'>{badge.title}</Heading>
            </CardHeader>
            <CardBody pt="8px" pl="20px">
              <Text>{badge.description}</Text>
            </CardBody>
          </Card>
        )
      })}
    </SimpleGrid>
  );
}

export default MethodologyBadges;
