import React from "react";

import {Box, Center} from "@chakra-ui/react"

import BasicCarousel from "../../components/BasicCarousel";
import {CourseData} from '../../types'
import CourseCard from "./CourseCard";

interface CourseCardCaourselProps {
 coursesData: CourseData[],
};

const CourseCardCaoursel = ({coursesData}: CourseCardCaourselProps) => {
  return (
    <BasicCarousel>
      {coursesData.map((courseData) => (
        <Center h={620} key={courseData.name}>
          <Box h={570} maxW="500px" marginX={3}>
            <CourseCard courseData={courseData} />
          </Box>
        </Center>
      ))}
    </BasicCarousel>
  )
};


export default CourseCardCaoursel;
