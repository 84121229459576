import React from "react";

import { Box, Heading, Text } from "@chakra-ui/react"

import BasePage from "../BasePage"
import SubscriptionsTable from "./SubscriptionsTable";
import ContributionsTable from "./ContributionsTable";


const ContributionsPage = () => {
  return BasePage(
    <>
      <Heading as="h2" size="lg">
        Contributions
      </Heading>
      <Text pt="1vh" pb="5vh" fontSize="md">
        Summary of your CDR credit purchases
      </Text>
      <Box w="100%" p={4} pb={8} bg="white" ml={2} mb="2rem" borderRadius="xl">
        <SubscriptionsTable />
      </Box>
      <Box w="100%" p={4} pb={8} bg="white" ml={2} borderRadius="xl">
        <ContributionsTable />
      </Box>
    </>
  );
};

export default ContributionsPage;
