import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Center, Image, VStack } from '@chakra-ui/react'

import {Icon, StarIcon, SunIcon } from '@chakra-ui/icons'
import { FaBookOpen, FaHome } from "react-icons/fa";

import NavbarItem from './NavbarItem';
import LoginButton from "../buttons/LoginButton";
import LogoutButton from "../buttons/LogoutButton";

const Navbar = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Box 
      w="12rem"
      position="fixed" 
      top={0} 
      left={0} 
      height="100vh" 
      bg="white" 
      zIndex="1000">

      <Center marginY="1rem" >
        <Image
          src="https://cdn.dorik.com/tmp/images/Zopeful_logo_1-(1)_w8jliv29.png"
          w="10rem"
          h="2.5rem"
          alt="Zopeful Climate Logo"
          />
      </Center>

      {!isAuthenticated && (
          <Box alignItems="left" minW="12rem">
            <LoginButton />
          </Box>
      )}

      {isAuthenticated && (
        <Center minW="11rem" alignItems="center">
          <VStack>
              {NavbarItem("/dashboard", "Dashboard", <Icon as={FaHome} />)}
              {NavbarItem("/contributions", "Contributions", <StarIcon />)}
              {NavbarItem("/learning", "Learning Hub", <Icon as={FaBookOpen} />)}
              {NavbarItem("/purchase", "Purchase CDR", <SunIcon />)}
          </VStack>
          <Center minW="12rem" bottom="5vh" position="absolute">
            <LogoutButton />
          </Center>
        </Center>

      )}
    </Box>
  );
}

export default Navbar;
