import React from "react";
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box, 
  Button,
  Card, 
  Flex,
  Heading,
  Image, 
  Stack, 
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react"

import {PortfolioPartnerData, SolutionTypeData} from '../../types'

interface SolutionTypeLearningCardProps {
    solutionTypeData: SolutionTypeData,
    partnersData: PortfolioPartnerData[],
};

const SolutionTypePartnersCard = (
  {solutionTypeData, partnersData}: SolutionTypeLearningCardProps
) => {
  return (
    <Card height="100%" borderRadius="xl" p={4}>
      <Button 
        as={ReactRouterLink} 
        to={`/solution-type/${solutionTypeData.id}`} 
        paddingY={1.5}
        mb={4} 
        borderRadius="2xl" 
        verticalAlign="middle"
        colorScheme="green"
        >
        <Heading as="h4" size="sm">
          {solutionTypeData.name}
        </Heading>
      </Button>

      <Tabs height="100%" size='sm' colorScheme='green'>
        <TabList>
          {partnersData.map((partner) => (<Tab key={partner.name}>{partner.name}</Tab>))}
        </TabList>
        <TabPanels height="100%">
          {partnersData.map((partner) => (
            <TabPanel height="100%" key={partner.name} paddingY={3}>
              <Flex direction="column" height="100%">
                <Stack spacing={3} flex="1">
                  <Image 
                    src={partner.staticImageLink} 
                    maxH={300}
                    objectFit="contain"
                    alt={`Photo of ${solutionTypeData.name} solution type`} 
                    borderRadius="xl"
                  />
                  <Text>{partner.shortDescription}</Text>
                </Stack>

                <Box textAlign="right" pb={6}>
                  <Button as={ReactRouterLink} to={`/partner/${partner.id}`} colorScheme="green">
                    Read more
                  </Button>
                </Box>
              </Flex>
            </TabPanel>
          )) }
        </TabPanels>
      </Tabs>

    </Card>
  )
};


export default SolutionTypePartnersCard;
