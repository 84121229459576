import React from "react";
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Center,
  Image,
  Heading,
  HStack,
  Stack,
} from "@chakra-ui/react"

const Markdown = React.lazy(() => import("react-markdown"));

interface InformationPageLinkCardProps {
  imageLink: string;
  heading: string;
  description: string;
  pageLink: string;
};

const InformationPageLinkCard = (
    {imageLink, heading, description, pageLink}: InformationPageLinkCardProps
) => {

  return (
    <Card maxW="m" maxH="300px" borderRadius="xl" paddingY={6} paddingX={8}>
      <HStack spacing={8} alignItems="start">
        <Box w="40%" h="100%">
          <Center>
            <Image 
              maxH="250px"
              objectFit="contain" 
              src={imageLink} 
              alt={`Photo for ${heading}`} 
              borderRadius="xl"
            />
          </Center>
        </Box>

        <Box w="60%" h="100%">
          <Stack spacing={2} h="100%">
            <Heading as="h2" size="md">{heading}</Heading>
            <Markdown>{description}</Markdown>
            <Button 
              as={ReactRouterLink} 
              to={pageLink}
              colorScheme="green" 
              alignSelf="flex-end"
              mt={4}
            >
              Read more
            </Button>
          </Stack>
        </Box>
      </HStack>
    </Card>
  )
};


export default InformationPageLinkCard;
