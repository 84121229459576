import React from "react";

import {
  Button, 
  Card, 
  CardBody, 
  Center,
  Flex, 
  Heading, 
  Link, 
  Stack, 
  Text
} from "@chakra-ui/react"

import {CourseData} from '../../types'

interface CourseCardProps {
 courseData: CourseData,
};

const CourseCard = ({courseData}: CourseCardProps) => {
  return (
    <Card height="100%" borderRadius="xl">
      {/* <Image 
        src="/assets/placeholder.jpg"
        alt={`Photo of for course ${courseData.name}`} 
        borderTopRadius="xl"
        objectFit="contain"
        /> */}
      <Center 
        borderTopRadius="xl" 
        color={courseData.textColor} 
        bgColor={courseData.color} 
        height="300px"
      >
        <Heading as="h2" size="lg">{courseData.name}</Heading>
      </Center>
      <CardBody p={4}>
        <Flex direction="column" height="100%">
          <Stack spacing={2} flex="1">
            <Heading as="h5" size="xs">{courseData.preface.toUpperCase()}</Heading>
            <Text>{courseData.description}</Text>
          </Stack>
          <Link href={courseData.informationLink} isExternal textAlign="right" mb={3} mr={4}>
            <Button colorScheme="green">Register now</Button>
          </Link>
        </Flex>
      </CardBody>
    </Card>
  )
};


export default CourseCard;
