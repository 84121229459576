
import {ReactElement} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1680 },
    items: 3
  },
  smallDesktop: {
    breakpoint: { max: 1680, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

interface BasicCarouselProps {
  children: ReactElement<any>[];
}


const BasicCarousel = ({children}: BasicCarouselProps) => {
  return (
    <Carousel
      responsive={responsive}
      draggable
      swipeable
      keyBoardControl
      infinite
      renderButtonGroupOutside
      showDots
    >
      {children}
    </Carousel>
  );
}

export default BasicCarousel;
